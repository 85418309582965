//
//
//
//
//

import consumer_department_getDepartmentUsers from "@/lib/data-service/haolv-default/consumer_department_getDepartmentUsers";
import Transfer from "@/component/transfer/1.0.1/index.vue";
import pageList from "@/lib/data-service/haolv-default/consumer_web_staff_pageList";
import getStaff from "@/lib/data-service/haolv-default/consumer_role_getStaff";
import getExternalCustomerList from "@/lib/data-service/haolv-default/consumer_web_externalCustomer_getExternalCustomerList";
export default {
  name: "SelectApprover",
  data() {
    return {
      treeData: [],
      list: []
    };
  },
  props: {
    businessPeople: {
      type: Array
    },
    approverSelectList: {
      type: Array
    },
    approverType: {
      // pageList:请求所有角色   getStaff:根据id请求角色
      type: String,
      default: "pageList"
    },
    requestStatus: {
      type: [Number, String]
    },
    id: {
      type: [Number, String]
    }
  },
  methods: {
    getPageList() {
      pageList({ pageSize: 10000 }).then(res => {
        console.log(res);
      });
    },
    getItem(val) {
      let id = this.id;
      console.log(val, id);
      this.$emit(`update:${id ? "approverSelectList" : "businessPeople"}`, val);
    },
    add_role(type, row) {
      let mentUser = datas => {
        const list = datas.map(data => {
          let obj = {};
          obj.deptId = data.deptId;
          obj.deptName = data.deptName;
          obj.children = [];
          obj.userList = [];
          if (data.deptList.length) {
            obj.children = mentUser(data.deptList);
          } else {
            obj.userList = data.staffList.map(staff => {
              return { id: staff.subUserId, name: staff.subStaffName };
            });
          }
          return obj;
        });
        return list;
      };
      let customerList = datas => {
        let list = datas.map(item => {
          let obj = { id: item.userId, name: item.customerName };
          return obj;
        });
        return [
          {
            deptId: 903,
            deptName: "外部人员",
            children: [],
            userList: list
          }
        ];
      };
      if (this.requestStatus == 1) {
        Promise.all([
          getExternalCustomerList({ pageSize: 100000 }),
          consumer_department_getDepartmentUsers()
        ]).then(values => {
          console.log(values);
          let list1 = mentUser(values[1].datas);
          let list2 = customerList(values[0].datas.list);
          this.treeData = [...list1, ...list2];
        });
      } else {
        consumer_department_getDepartmentUsers().then(res => {
          let activities = this.$attrs.activities;

          activities && searchTree(res.datas, activities);
          this.treeData = mentUser(res.datas);
          function searchTree(list, activities) {
            if (list instanceof Array && list.length) {
              list.some(item => {
                if (item.deptList.length) {
                  searchTree(item.deptList, activities);
                  return true;
                }
                if (item.staffList.length) {
                  item.staffList.map((items, index) => {
                    activities.map(name => {
                      if (name.approverUserName === items.subStaffName) {
                        item.staffList.splice(index, 1);
                      }
                    });
                  });
                }
              });
            }
          }
        });
      }
    },
    _getStaff(id) {
      getStaff({ id }).then(res => {
        console.log(res);
        const list = res.datas;
        let formatData = datas => {
          const list = datas.map(data => {
            let obj = {};
            obj.deptId = data.deptId || 1;
            obj.deptName = data.deptName || "默认部门";
            obj.children = [];
            obj.userList = [];
            // if (data.deptList.length) {
            //   obj.children = formatData(data.deptList);
            // } else {
            //   obj.userList = data.staffList.map(staff => {
            //     return { id: staff.subStaffId, name: staff.subStaffName };
            //   });
            // }
            obj.userList = [{ id: data.userId, name: data.staffName }];
            return obj;
          });
          return list;
        };
        this.treeData = formatData(list);
      });
    }
  },
  created() {
    this.add_role();
  },
  components: {
    Transfer
  },
  activated() {},
  watch: {
    businessPeople(val) {
      this.list = val.map(item => item.id);
    },
    id(val) {
      this._getStaff(this.id);
    }
  },
  computed: {}
};
